<div class="filter-panel">
  <locumsnest-modal
    [show]="true"
    [showCloseButton]="false"
    [title]="'Choose your filters:'"
    (close)="onCloseModal()"
  >
    <ng-container body>
      <div class="body">
        <div class="filter-categories">
          <span class="title">Selected</span>
          <div class="scroller">
            @for (filterGroup of filterGroups; track filterGroup.displayName) {
              <div class="scroller-inside">
                <div class="filter-group" (click)="onFilterGroupClicked(filterGroup)">
                  <div class="filter-group--name">
                    <locumsnest-check-box
                      class="check-box"
                      theme="blue"
                      iconName="filter-tick"
                      [displayText]="filterGroup.displayName"
                      [ngModel]="true"
                      [isDisabled]="true"
                      [keepColorsOnDisable]="true"
                    />
                  </div>
                  <div
                    class="filter-group--count"
                    [ngClass]="{
                      'fade-out-on-disabled': selectedFilterGroup !== filterGroup.slug
                    }"
                  >
                    ({{ filterGroup.count }})
                  </div>
                </div>
                @if (
                  showDepartmentSection &&
                  filterGroup.slug === 'Profession' &&
                  selectedFilterGroup === 'Profession'
                ) {
                  <div class="profession-selected">
                    @for (
                      filterCategory of getProfessionFilterCategories();
                      track trackByFilterCategory
                    ) {
                      @if (filterCategory.selected) {
                        <div
                          class="filter-group-selected"
                          (click)="onCategoryClicked(filterCategory)"
                        >
                          <div class="filter-group-selected--name">
                            <locumsnest-check-box
                              class="check-box"
                              theme="blue"
                              iconName="filter-tick"
                              [displayText]="filterCategory.displayName"
                              [ngModel]="true"
                              [isDisabled]="true"
                            />
                          </div>
                          <div
                            class="filter-group-selected--count"
                            [ngClass]="{
                              'fade-out-on-disabled': selectedProfession.id !== filterCategory.id
                            }"
                          >
                            ({{ filterCategory.count }})
                          </div>
                        </div>
                      }
                    }
                  </div>
                }
              </div>
            }
          </div>
        </div>
        @if (!showDepartmentSection) {
          <div style="width: 100%">
            @for (filterGroup of filterGroups; track trackBySlug) {
              @if (filterGroup.slug === selectedFilterGroup) {
                <span class="title">Please choose your {{ filterGroup?.slug }}(s)</span>
                <p class="notes">{{ filterGroup?.notes }}</p>
                <div class="scroller">
                  <div class="scroller-inside">
                    @if (
                      !showDepartmentSection &&
                        (filterGroup.filterCategories || filterGroup.options);
                      as filterGroups
                    ) {
                      <div style="display: flex; flex-direction: column; gap: 7px">
                        @for (filterCategory of filterGroups; track trackByFilterCategoryOrOption) {
                          <div style="display: flex; flex-direction: column; gap: 7px">
                            <div style="display: flex">
                              <locumsnest-check-box
                                theme="blue"
                                iconName="filter-tick"
                                [displayText]="
                                  selectedFilterGroup === 'Profession' ||
                                  selectedFilterGroup === 'Ward'
                                    ? filterCategory['displayName']
                                    : filterCategory['name']
                                "
                                [ngModel]="filterCategory.selected"
                                (checked)="
                                  selectedFilterGroup === 'Profession' ||
                                  selectedFilterGroup === 'Ward'
                                    ? onFilterCategorySelected(filterCategory, selectedFilterGroup)
                                    : onOptionSelected(filterCategory, 'Site')
                                "
                              />
                              @if (
                                selectedFilterGroup === 'Ward' &&
                                filterCategory['filterOptions'][0].options.length > 1
                              ) {
                                <span
                                  class="select-all-ward-options"
                                  (click)="
                                    onFilterCategorySelected(filterCategory, selectedFilterGroup)
                                  "
                                >
                                  -
                                  {{
                                    getWardRostersSelected(
                                      filterCategory['filterOptions'][0].options
                                    )
                                      ? 'Unselect all'
                                      : 'Select all'
                                  }}
                                </span>
                              }
                            </div>
                            @if (
                              selectedFilterGroup === 'Ward' &&
                              filterCategory['filterOptions'][0].options.length
                            ) {
                              @for (
                                roster of filterCategory['filterOptions'][0].options;
                                track trackByOption
                              ) {
                                <div style="margin-left: 15px">
                                  <div style="display: flex">
                                    <locumsnest-check-box
                                      theme="green"
                                      iconName="filter-tick"
                                      [displayText]="roster.name"
                                      [ngModel]="roster.selected"
                                      (checked)="onOptionSelected(roster, 'Roster')"
                                    />
                                  </div>
                                </div>
                              }
                            }
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              }
            }
          </div>
        }
        <div>
          @if (showDepartmentSection) {
            <div>
              @if (selectedFilterGroup === 'Profession') {
                <span class="title">
                  {{ selectedProfession.title }}: narrow down by department</span
                >
              }
              @if (selectedFilterGroup === 'Ward') {
                <span class="title"> {{ selectedWard.name }}: narrow down by roster</span>
              }
              <span
                class="select-all-options"
                (click)="
                  onToggleProfessionDepartments(
                    !getProfessionDepartmentsSelected(),
                    selectedFilterGroup
                  )
                "
              >
                - {{ getProfessionDepartmentsSelected() ? 'Unselect all' : 'Select all' }}
              </span>
            </div>
          }
          <div class="scroller">
            @if (
              showDepartmentSection &&
              selectedFilterGroup === 'Profession' &&
              getProfessionDepartments().length
            ) {
              <div class="scroller-inside">
                @for (
                  department of getProfessionDepartments()[0].options;
                  track trackByOption($index, department)
                ) {
                  <div style="display: flex; flex-direction: column; gap: 7px">
                    <div style="display: flex">
                      <locumsnest-check-box
                        theme="blue"
                        iconName="filter-tick"
                        [displayText]="department.name"
                        [ngModel]="department.selected"
                        (checked)="onOptionSelected(department, 'Department access')"
                      />
                      @if (getProfessionSubDepartments(department).length > 1) {
                        <span
                          class="select-all-options"
                          (click)="onOptionSelected(department, 'Department access')"
                        >
                          - {{ department.selected ? 'Unselect all' : 'Select all' }}
                        </span>
                      }
                    </div>
                    @if (getProfessionSubDepartments(department).length > 1) {
                      <div
                        style="margin-left: 15px; display: flex; flex-direction: column; gap: 7px"
                      >
                        @for (
                          subDepartment of getProfessionSubDepartments(department);
                          track trackBySubDepartment($index, subDepartment)
                        ) {
                          <locumsnest-check-box
                            theme="green"
                            iconName="filter-tick"
                            [displayText]="subDepartment.name"
                            [ngModel]="subDepartment.selected"
                            (checked)="onOptionSelected(subDepartment, 'Sub-Department access')"
                          />
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container #footer footer>
      <div class="panel-footer">
        <div class="select-all">
          <locumsnest-check-box
            theme="blue"
            iconName="filter-tick"
            [displayText]="'Select all'"
            [ngModel]="canSelectAll"
            [isDisabled]="selectAllInProgress"
            (checked)="onSelectAllClicked($event)"
          />
          @if (selectAllInProgress) {
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          }
        </div>
        @if (filterAlert !== '') {
          <div class="filter-alert-container">
            <div class="filter-alert">{{ filterAlert }}</div>
          </div>
        }
        <div class="footer-buttons">
          <ng-content #footerButtons />
        </div>
      </div>
    </ng-container>
  </locumsnest-modal>
</div>
